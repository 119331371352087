<template>
  <div id="app">

    <el-row>
      <el-col :span="6" class="rows">
        <el-select v-model="deviceType" filterable placeholder="请选择" :clearable="true" @change="selectDeviceType">
          <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="rows" style="margin-left:-360px">
        <el-button type="primary" @click="queryInfo">查询</el-button>
      </el-col>
      <el-col :span="6" class="rows" style="margin-left:-515px">
        <el-button type="success" @click="addDeviceTypeInfo" v-if="userRightList.addJuris=='1'">添加设备类型</el-button>
      </el-col>
    </el-row>

    <!-- 设备类型展示 -->
    <div>
      <el-table :data="deviceTypeList" :stripe="true" :border="true" height="700">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" class="demo-table-expand">
              <el-form-item label="静态属性：" prop="value" style="margin-right: 50px">
                <span v-for="item in props.row.propertiesList" :key="item">{{ item }} -
                </span>
              </el-form-item>
              <el-form-item label="问题描述" prop="value" style="margin-right: 50px">
                <span v-for="item in props.row.questionValueList" :key="item">{{ item }} -
                </span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
        <el-table-column prop="id" label="设备类型ID" align="center"></el-table-column>
        <el-table-column prop="name" label="设备类型" align="center"></el-table-column>
        <el-table-column label="操作" width="150" align="center" v-if="userRightList.addJuris=='1'">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="delet(scope.row.id)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加/编辑 设备类型 -->
    <el-dialog :title="title" :visible.sync="showAddOrEditDevice" :before-close="cancelOperation" width="40%" style="background:rgba(0,0,0,0.5)">
      <div style="height:300px;overflow:scroll;scrollbar-width:none">
        <el-form label-position="right" label-width="120px" :model="deviceTypeInfoObj" :rules="rules">

          <!-- 设备类型ID -->
          <el-form-item label="设备类型ID" prop="id">
            <el-input v-model="deviceTypeInfoObj.id" placeholder="请输入设备类型ID" :disabled="isEdit"></el-input>
          </el-form-item>

          <!-- 设备类型名称 -->
          <el-form-item label="设备类型名称" prop="name">
            <el-input v-model="deviceTypeInfoObj.name" placeholder="请输入设备类型名称"></el-input>
          </el-form-item>

          <!-- 静态属性 -->
          <el-form-item label="静态属性" prop="staticAttributes">
            <el-input v-model="staticAttributes" placeholder="请输入静态属性名称" style="width:80%;margin-right:20px"></el-input>
            <el-button @click="append('staticAttributes')" type="primary" size="mini" icon="el-icon-plus" circle :disabled="staticAttributes==''"></el-button>
            <el-button @click="remove('staticAttributes')" type="danger" size="mini" icon="el-icon-minus" circle></el-button>
            <el-row :gutter="10" style="width:95%">
              <el-col :span="6" v-for="item in deviceTypeInfoObj.staticAttributesList">{{item}}</el-col>
            </el-row>
          </el-form-item>

          <el-form-item label="问题描述" prop="problemDescription">
            <el-input v-model="problemDescription" placeholder="请输入相关问题描述" style="width:80%;margin-right:20px"></el-input>
            <el-button @click="append('problemDescription')" type="primary" size="mini" icon="el-icon-plus" circle :disabled="problemDescription==''"></el-button>
            <el-button @click="remove('problemDescription')" type="danger" size="mini" icon="el-icon-minus" circle></el-button>
            <el-row :gutter="10" style="width:95%">
              <el-col :span="6" v-for="item in deviceTypeInfoObj.problemDescriptionList">{{item}}</el-col>
            </el-row>
          </el-form-item>

        </el-form>

      </div>
      <div slot="footer">
        <el-button @click="cancelOperation">取 消</el-button>
        <el-button type="primary" @click="submitDeviceInfo">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
import DeviceType from '@/api/facilitiesManagement/DeviceType'
export default {
  data() {
    return {
      isEdit: false,
      // 添加弹框
      showAddOrEditDevice: false,

      // 搜索设备类型
      deviceType: '',

      deviceTypeList: [],

      staticAttributes: '',
      dynamicProperties: '',
      problemDescription: '',

      deviceTypeInfoObj: {
        id: '',
        name: '',
        staticAttributesList: [],
        // dynamicProperties: [],
        problemDescriptionList: [],
      },

      rules: {
        id: [{ required: true, message: "设备类型ID不能为空！", trigger: 'blur' }],
        name: [{ required: true, message: "设备类型名称不能为空！", trigger: 'blur' }],
        staticAttributes: [{ required: true, message: "静态属性名称不能为空！", trigger: 'blur' }],
        // dynamicProperties: [{ required: true, message: "动态属性名称不能为空！", trigger: 'blur'}],
        problemDescription: [{ required: true, message: "问题描述不能为空！", trigger: 'blur' }],
      },

      current: 1,
      limit: 10,
      name: '',
      total: 1,
      title: '',

      userRightList: [],
    }
  },
  created() {
    this.getUsers()
    this.getAllDeviceType()

  },
  methods: {
    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          console.log(res.data.jurisList[i].menuName);
          if (res.data.jurisList[i].menuName == "DeviceInfo") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },


    // 查全部设备类型
    async getAllDeviceType() {
      const { data: res } = await DeviceType.getDeviceManagementTypeInfo(this.limit, this.current, this.name);
      this.deviceTypeList = res.data.list;
      this.totals = res.data.total;
      console.log(res);
    },
    append(param) {
      console.log(param);
      // 静态属性
      console.log(this.deviceTypeInfoObj);
      if (param == 'staticAttributes') {
        this.deviceTypeInfoObj.staticAttributesList.push(this.staticAttributes);
        console.log();
        this.staticAttributes = '';
      }
      // 动态属性
      //   if (param == 'dynamicProperties') {

      //   }
      // 问题描述
      if (param == 'problemDescription') {
        this.deviceTypeInfoObj.problemDescriptionList.push(this.problemDescription);
        this.problemDescription = '';
      }
    },
    remove(param) {
      // 静态属性
      if (param == 'staticAttributes') {
        this.deviceTypeInfoObj.staticAttributesList.pop();
      }
      // 动态属性
      //   if (param == 'dynamicProperties') {

      //   }
      // 问题描述
      if (param == 'problemDescription') {
        this.deviceTypeInfoObj.problemDescriptionList.pop();
      }
      //   console.log(param);
    },



    // 查询方法
    queryInfo() {
      console.log(this.name);
      console.log(this.deviceType);
      this.name = this.deviceType;
      this.getAllDeviceType();
    },

    // 添加设备类型/编辑 确认按钮
    submitDeviceInfo() {
      console.log();
      // 添加
      if (!this.isEdit) {
        console.log(this.deviceTypeInfoObj);
        DeviceType.addDeviceManagementType(this.deviceTypeInfoObj.id, this.deviceTypeInfoObj.name, this.deviceTypeInfoObj.staticAttributesList, this.deviceTypeInfoObj.problemDescriptionList).then(res => {
          if (res.data.message == "添加成功") {
            this.$message({
              type: 'success',
              message: res.data.message
            });
          } else if (res.data.message == "添加失败") {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          } else if (res.data.message == "已存在") {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
          this.getAllDeviceType()     // 刷新
        })
        this.clearWay();        // 清除
        this.showAddOrEditDevice = false;

        // 编辑
      } else {
        DeviceType.updateDeviceManagementType(this.deviceTypeInfoObj.id, this.deviceTypeInfoObj.name, this.deviceTypeInfoObj.staticAttributesList, this.deviceTypeInfoObj.problemDescriptionList).then(res => {
          if (res.data.message == "修改成功") {
            this.$message({
              type: 'success',
              message: res.data.message
            });
          } else if (res.data.message == "修改失败") {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
          this.getAllDeviceType()    // 刷新

          this.clearWay();        // 清除
          this.showAddOrEditDevice = false;

        })
      }
    },
    // 取消按钮
    cancelOperation() {
      this.clearWay();
      this.showAddOrEditDevice = false;
    },


    // 删除设备类型
    delet(id) {

      this.$confirm(
        "确定删除吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        DeviceType.deleteDeviceManagementType(id).then(res => {
          if (res.data.message == "删除成功") {
            this.$message({
              type: 'success',
              message: res.data.message
            });
          } else if (res.data.message == "删除失败") {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
          this.getAllDeviceType()    // 刷新
        })
      })
    },

    // 添加按钮
    addDeviceTypeInfo() {
      this.clearWay();
      this.showAddOrEditDevice = true;
      this.title = "添加设备类型"
      this.isEdit = false;

    },

    // 编辑设备类型
    edit(row) {
      this.isEdit = true;
      this.showAddOrEditDevice = true;
      this.title = "修改设备类型"

      console.log(row);

      this.deviceTypeInfoObj.id = row.id
      this.deviceTypeInfoObj.name = row.name
      this.deviceTypeInfoObj.staticAttributesList = row.propertiesList
      this.deviceTypeInfoObj.problemDescriptionList = row.questionValueList

      console.log(this.deviceTypeInfoObj);

    },

    //   清空表单方法
    clearWay() {
      this.deviceTypeInfoObj.id = ""
      this.deviceTypeInfoObj.name = ""
      this.deviceTypeInfoObj.staticAttributesList = []
      this.deviceTypeInfoObj.problemDescriptionList = []

    },



    selectDeviceType(value) {
      console.log(value);
      console.log(this.deviceType);
    }

  }

}
</script>

<style lang="scss" scoped>
.rows{
  margin: 10px 10px 10px 0;
}
/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: 600;
  color: rgb(7, 54, 54);
}
/deep/.el-dialog__title::before {
  content: "丨";
  font-weight: 600;
  color: rgb(7, 54, 54);
}
</style>
import request from '@/../utils/request'
const api_name = '/iotechserver/WechatDeviceManagementDeviceTypeController'

export default {
    // 查询所有设备类型
    getDeviceManagementTypeInfo(limit, current, name) {
        return request({
            url: `${api_name}/getDeviceManagementTypeInfo`,
            method: 'post',
            data: {
                limit: limit,
                current: current,
                name: name,
            }
        })
    },

    // 添加设备类型
    addDeviceManagementType(id, name, propertiesList, questionValueList) {
        return request({
            url: `${api_name}/addDeviceManagementType`,
            method: 'post',
            data: {
                id: id,
                name: name,
                propertiesList: propertiesList,
                questionValueList: questionValueList,
            }
        })
    },

    // 编辑设备类型
    updateDeviceManagementType(id, name, propertiesList, questionValueList) {
        return request({
            url: `${api_name}/updateDeviceManagementType`,
            method: 'post',
            data: {
                id: id,
                name: name,
                propertiesList: propertiesList,
                questionValueList: questionValueList,
            }
        })
    },

    // 删除设备类型
    deleteDeviceManagementType(id) {
        return request({
            url: `${api_name}/deleteDeviceManagementType/${id}`,
            method: 'get',
        })
    },
}